<template>
  <div class="bedList fatherHeight">
    <el-tabs
      v-model="tagName"
      type="card"
      class="patent-tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane label="基本信息" name="basic">
        <basic ref="basicRef"></basic>
      </el-tab-pane>
      <el-tab-pane label="运行记录" name="operation">
        <operation ref="operationRef"></operation>
      </el-tab-pane>
      <el-tab-pane label="保养记录" name="upkeep">
        <upkeep ref="upkeepRef"></upkeep>
      </el-tab-pane>
      <el-tab-pane label="维修记录" name="maintain">
        <maintain ref="maintainRef"></maintain>
      </el-tab-pane>
      <!-- <el-tab-pane label="设备交班" name="shift">
        <advice></advice>
      </el-tab-pane> -->
      <el-tab-pane label="消毒记录" name="sterilize">
        <sterilize ref="sterilizeRef"></sterilize>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref, defineExpose } from 'vue'
import basic from './basic/index.vue'
import operation from './operation/index.vue'
import upkeep from './upkeep/index.vue'
import maintain from './maintain/index.vue'
import sterilize from './sterilize/index.vue'

const tagName = ref('basic')
const basicRef = ref(null)
const operationRef = ref(null)
const upkeepRef = ref(null)
const maintainRef = ref(null)
const sterilizeRef = ref(null)
const handleClick = (tab) => {
  if (tab.props.name == 'basic') {
    basicRef.value.initData()
  } else if (tab.props.name == 'operation') {
    operationRef.value.initData()
  } else if (tab.props.name == 'upkeep') {
    upkeepRef.value.initData()
  } else if (tab.props.name == 'maintain') {
    maintainRef.value.initData()
  } else if (tab.props.name == 'sterilize') {
    sterilizeRef.value.initData()
  }
}
const initData = () => {
  tagName.value = 'basic'
  basicRef.value.initData()
}
defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.bedList {
  &:deep(.el-tabs) {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 48px);
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
